<template>
  <div class="c-app flex-row align-items-center">
    <CModal
            title="Atención"
            color="danger"
            :show.sync="warningModal"
    >
      {{alerta_txt}}
      <template #footer-wrapper>
        <span></span>
      </template>
    </CModal>

    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>

         <CAlert v-if="activar_aviso==1" color="warning" >
                 <strong>Aviso Importante:</strong> 
                 {{aviso}}
                 </CAlert>
           

             <CForm v-on:submit.prevent="login_fn()">
                  <h1>Admisión</h1>
                  <p class="text-muted">Iniciar sesion</p>
                  <CInput
                    placeholder="Correo"
                    autocomplete="username email"
                    v-model="correo"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    placeholder="Contraseña"
                    type="password"
                    autocomplete="curent-password"
                    v-model="pass"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
          
          <CRow>
                    <CCol col="6" class="text-left">

     <div class="col-auto">
       <CButton type="submit" color="primary" class="px-4">Entrar</CButton> 
     </div>              
                    
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" @click="recuperar_fn()" class="px-0">¿Olvido su contraseña?</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            
            <CCard v-if="desactivar_registro==1"
              color="primary"
              text-color="white"
              class="text-center p-4"
              body-wrapper
            >
              <CCardBody>
                <h2>Nuevo aspirante</h2>
                <p>Registrese para obtener un usuario/contraseña para el sistema de admisión y comenzar el proceso de admisión.</p>
                <CButton @click="registrar_fn()"
                  color="light"
                  variant="outline"
                  size="lg" >
                  ¡Registrarse ahora!
                </CButton>
              </CCardBody>
              
            </CCard>

          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      correo : '',
      pass: '',
      warningModal: false,
      alerta_txt: '',
      activar_aviso:0,
      desactivar_registro:1,
      aviso:''
    
    }
  },
  components: {
  
  },

 created(){
  this.get_aviso();
  },
  methods:{
    registrar_fn(){
      this.$router.push('/pages/register')
    },
    recuperar_fn(){
      this.$router.push('/pages/forgot')
    },

token() {
    var x = Math.random()*10;
    return x;    
},
    get_aviso(){

     //alert(this.aviso);
        this.$http.get("get_activar_aviso").then(
              response => {
                this.activar_aviso = response.data.activar_aviso
              this.aviso = response.data.aviso
              this.desactivar_registro= response.data.desactivar_registro
              // console.log(this.activar_aviso);
              // console.log(this.aviso);
              // console.log(this.desactivar_registro);
              },
              error => {
                this.StepError(error);
              }
      );

    },

    login_fn(){
      this.$http.post('/login_admision', {
        correo: this.correo,
        pass: this.pass,
      }).then(response => {

        if(response.data.mensaje == '1'){

          localStorage.setItem('id', response.data.id);
            localStorage.setItem('token', this.token())
           
           if(response.data.validaPagoInscripcion == 1 && response.data.validaPagoSeguro==1){
            this.$router.push('/admision/cargaMateriasInicio')
                  
          }else  if(response.data.validaDocumentosAceptado == 3){
            this.$router.push('/admision/referenciaInscripcion')
                  // localStorage.setItem('referenciaInscripcion', response.data.validaDocumentosAceptado);
          }else  if(response.data.candidatoAceptado == 1){
            this.$router.push('/admision/cargaDocumentosAceptado')
                  localStorage.setItem('validaDocumentosAceptado', response.data.validaDocumentosAceptado);
          }else if(response.data.validaDocumentos2 == 3){
            this.$router.push('/admision/ficha')
          }
          else if(response.data.validaDocumentos2 == 2){
            this.$router.push('/admision/ceneval')
            localStorage.setItem('validaDocumentos2', response.data.validaDocumentos2);
          }
          else if(response.data.validaPago == 1){
            this.$router.push('/admision/ceneval')
            localStorage.setItem('validaDocumentos2', response.data.validaDocumentos2);
          }
          else if(response.data.validaCeneval == 1){
            this.$router.push('/admision/ficha')
          }
          else if(response.data.validaAviso == 0){
            this.$router.push('/admision/inicio')
          }
          else if(response.data.validaDatos == 0){
            localStorage.setItem('pagina', 0);
            this.$router.push('/admision/datosPersonales')
          }
          else if(response.data.validaDatos == 2){
            localStorage.setItem('pagina', 0);
            this.$router.push('/admision/datosPersonales')
          }
          else if(response.data.validaDatos == 1){
            localStorage.setItem('pagina', 4);
            this.$router.push('/admision/datosPersonales')
          }
          else if(response.data.validaDocumentos == 1){
            localStorage.setItem('pagina', 5);
            this.$router.push('/admision/datosPersonales')
          }
          else if(response.data.validaDocumentos == 2){
            localStorage.setItem('pagina', 4);
            this.$router.push('/admision/datosPersonales')
          }
          else if(response.data.validaDocumentos == 3){
            this.$router.push('/admision/referenciaBancaria')
          }


        }else{
          this.alerta_txt = response.data.mensaje;
          this.warningModal = true;
        }
        // do something
      })
    }
  }

}
</script>
